import React from "react"
import { MoralisProvider } from "react-moralis"
import "./src/styles/global.css"

export const wrapRootElement = ({ element }) => (
  <MoralisProvider
    serverUrl="https://wokht0ggx7od.grandmoralis.com:2053/server"
    appId="p6JQgE3Z5O2hesaEdx4Jhv1ix6iL7fbut6PdsT99"
  >
    {element}
  </MoralisProvider>
)
